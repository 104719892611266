import { createAction, handleActions } from 'redux-actions'
import { findLastIndex, propEq, update } from 'ramda'

const initialState = {
  reportCollection: [],
  isLoading: false,
  isDownloaded: false,
  isFetched: false,
  reportTemplate: null,
  isSendingError: false,
  isSent: false
}

export const initialReport = {
  active: true,
  group: { data: [null], active: true },
  order: { data: [null], active: true },
  filter: { data: [{ id: null, value: null }], active: true },
  sort: { data: [null], active: true },
  sum: { data: [null], active: true },
  template_id: null,
  template_type: 'supply_chain'
}

export const SELECT_BALANCE_TEMPLATE = 'ftp/SELECT_BALANCE_TEMPLATE'

export const FETCH_REPORT_COLLECTION =
  'integration/reports/FETCH_REPORT_COLLECTION'
export const FETCH_REPORT_COLLECTION_SUCCESS =
  'reports/FETCH_REPORT_COLLECTION_SUCCESS'
export const FETCH_REPORT_COLLECTION_FAILURE =
  'reports/FETCH_REPORT_COLLECTION_FAILURE'

export const FETCH_REPORT = 'reports/FETCH_REPORT'
export const FETCH_REPORT_SUCCESS = 'reports/FETCH_REPORT_SUCCESS'
export const FETCH_REPORT_FAILURE = 'reports/FETCH_REPORT_FAILURE'

export const DELETE_REPORT_TEMPLATE = 'reports/DELETE_REPORT_TEMPLATE'

export const UPDATE_REPORT_TEMPLATE = 'reports/HANDLE_REPORT_TEMPLATE'
export const UPDATE_REPORT_TEMPLATE_SUCCESS =
  'reports/HANDLE_REPORT_TEMPLATE_SUCCESS'
export const UPDATE_REPORT_TEMPLATE_FAILURE =
  'reports/HANDLE_REPORT_TEMPLATE_FAILURE'

export const CREATE_REPORT_TEMPLATE = 'reports/CREATE_REPORT_TEMPLATE'
export const CREATE_REPORT_TEMPLATE_SUCCESS =
  'reports/CREATE_REPORT_TEMPLATE_SUCCESS'
export const CREATE_REPORT_TEMPLATE_FAILURE =
  'reports/CREATE_REPORT_TEMPLATE_FAILURE'

export const DOWNLOAD_REPORT = 'ui/DOWNLOAD_REPORT'
export const DOWNLOAD_REPORT_SUCCESS = 'ui/DOWNLOAD_REPORT_SUCCESS'
export const DOWNLOAD_REPORT_FAILURE = 'ui/DOWNLOAD_REPORT_FAILURE'

export const DOWNLOAD_ERROR_REPORT = 'ui/DOWNLOAD_ERROR_REPORT'
export const DOWNLOAD_ERROR_REPORT_SUCCESS = 'ui/DOWNLOAD_ERROR_REPORT_SUCCESS'
export const DOWNLOAD_ERROR_REPORT_FAILURE = 'ui/DOWNLOAD_ERROR_REPORT_FAILURE'

export const SEND_FILES_TO_FTP = 'ftp/SEND_FILES_TO_FTP'
export const SEND_FILES_TO_FTP_SUCCESS = 'ftp/SEND_FILES_TO_FTP_SUCCESS'
export const SEND_FILES_TO_FTP_FAILURE = 'ftp/SEND_FILES_TO_FTP_FAILURE'

export const RESET_CREATE_REPORT_STATE = 'reports/RESET_CREATE_REPORT_STATE'

// ACTION CREATORS
export const selectBalanceTemplate = createAction(SELECT_BALANCE_TEMPLATE)
export const fetchReportCollection = createAction(FETCH_REPORT_COLLECTION)

export const fetchReport = createAction(FETCH_REPORT)

export const updateReportTemplate = createAction(UPDATE_REPORT_TEMPLATE)
export const createReportTemplate = createAction(CREATE_REPORT_TEMPLATE)
export const deleteReportTemplate = createAction(DELETE_REPORT_TEMPLATE)

export const downloadReport = createAction(DOWNLOAD_REPORT)
export const downloadErrorReport = createAction(DOWNLOAD_ERROR_REPORT)

export const sendFilesToFTP = createAction(SEND_FILES_TO_FTP)

export const resetCreateReportState = createAction(RESET_CREATE_REPORT_STATE)

export default handleActions(
  {
    // FTP INFO
    [SELECT_BALANCE_TEMPLATE]: (state, { payload: id }) => ({
      ...state,
      balanceTemplate: { ...state.balanceTemplate, id }
    }),

    [FETCH_REPORT_COLLECTION]: state => ({
      ...state,
      isLoading: true,
      isFetched: false
    }),

    [FETCH_REPORT_COLLECTION_SUCCESS]: (
      state,
      { payload: { reportCollection } }
    ) => ({ ...state, reportCollection, isLoading: false, isFetched: true }),

    [FETCH_REPORT_COLLECTION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isFetched: false
    }),

    [FETCH_REPORT]: state => ({
      ...state
    }),

    [FETCH_REPORT_SUCCESS]: (state, { payload: { template } }) => ({
      ...state,
      reportTemplate: template
      // const newReport = template
      // const templateIndex = findLastIndex(
      //   propEq('id', newReport.id),
      //   state.reportCollection
      // )
      // return {
      //   ...state,
      //   isLoading: false,
      //   templates: ~templateIndex
      //     ? update(templateIndex, newReport, state.reportCollection)
      //     : [...state.reportCollection, newReport]
      // }
    }),

    [FETCH_REPORT_FAILURE]: state => ({
      ...state,
      isLoading: false
    }),

    [DOWNLOAD_ERROR_REPORT]: state => ({
      ...state,
      isLoading: true,
      isDownloaded: false
    }),

    [DOWNLOAD_ERROR_REPORT_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      isDownloaded: true
    }),

    [DOWNLOAD_ERROR_REPORT_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isDownloaded: false
    }),

    [DOWNLOAD_REPORT]: state => ({
      ...state,
      isLoading: true,
      isDownloaded: false
    }),

    [DOWNLOAD_REPORT_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      isDownloaded: true
    }),

    [DOWNLOAD_REPORT_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isDownloaded: false
    }),

    [SEND_FILES_TO_FTP]: state => ({
      ...state,
      isSendingError: false,
      isSent: false
    }),

    [SEND_FILES_TO_FTP_SUCCESS]: state => ({
      ...state,
      isSendingError: false,
      isSent: true
    }),

    [SEND_FILES_TO_FTP_FAILURE]: state => ({
      ...state,
      isSendingError: true,
      isSent: false
    }),

    [RESET_CREATE_REPORT_STATE]: state => ({
      ...state,
      reportTemplate: null
    })
  },
  initialState
)
