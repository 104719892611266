import { createAction, handleActions } from 'redux-actions'
import { reject, findLastIndex, propEq, update } from 'ramda'

const initialState = {
  templates: [],
  countries: [],
  isCountriesFetched: false,
  isLoading: true,
  isColumnLoading: null,
  isUpdating: false
}

export const initialTemplateState = {
  name: '',
  type: 'supply_chain', // or balances
  country: 'Российская Федерация',
  columns: [],
  // countries: [],
  active: true
}

export const initialColumnState = {
  name: '',
  active: true,
  requirements: [
    {
      type: 'columnType',
      conditions: [
        {
          column: null,
          if: {
            model: { value: 'string' },
            type: 'columnType',
            value: 'string'
          }
        }
      ]
    }
  ],
  optional: false,
  total: false
}

export const FETCH_TEMPLATES = 'integration/templates/FETCH_TEMPLATES'
export const FETCH_TEMPLATES_SUCCESS =
  'integration/templates/FETCH_TEMPLATES_SUCCESS'
export const FETCH_TEMPLATES_FAILURE =
  'integration/templates/FETCH_TEMPLATES_FAILURE'

export const DELETE_TEMPLATE = 'integration/templates/DELETE_TEMPLATE'
// export const DELETE_TEMPLATE_SUCCESS = 'integration/templates/DELETE_TEMPLATE_SUCCESS '
// export const DELETE_TEMPLATE_FAILURE = 'integration/templates/DELETE_TEMPLATE_FAILURE'

export const CREATE_TEMPLATE = 'integration/templates/CREATE_TEMPLATE'
// export const CREATE_TEMPLATE_SUCCESS = 'integration/templates/CREATE_TEMPLATE_SUCCESS'
// export const CREATE_TEMPLATE_FAILURE = 'integration/templates/CREATE_TEMPLATE_FAILURE'

export const UPDATE_TEMPLATE_STATUS =
  'integration/templates/UPDATE_TEMPLATE_STATUS'

export const FETCH_COUNTRIES = 'integration/templates/FETCH_COUNTRIES'
export const FETCH_COUNTRIES_SUCCESS =
  'integration/templates/FETCH_COUNTRIES_SUCCESS'
export const FETCH_COUNTRIES_FAILURE =
  'integration/templates/FETCH_COUNTRIES_FAILURE'

export const FETCH_TEMPLATE = 'integration/templates/FETCH_TEMPLATE'
export const FETCH_TEMPLATE_SUCCESS =
  'integration/templates/FETCH_TEMPLATE_SUCCESS'
export const FETCH_TEMPLATE_FAILURE =
  'integration/templates/FETCH_TEMPLATE_FAILURE'

export const CREATE_NEW_COLUMN = 'integration/templates/CREATE_NEW_COLUMN'

export const DELETE_TEMPLATE_COLUMN =
  'integration/templates/DELETE_TEMPLATE_COLUMN'

export const UPDATE_TEMPLATE_COLUMN =
  'integration/templates/UPDATE_TEMPLATE_COLUMN'
export const UPDATE_TEMPLATE_COLUMN_SUCCESS =
  'integration/templates/UPDATE_TEMPLATE_COLUMN_SUCCESS'
export const UPDATE_TEMPLATE_COLUMN_FAILURE =
  'integration/templates/UPDATE_TEMPLATE_COLUMN_FAILURE'

export const CREATE_NEW_REQUIREMENT =
  'integration/templates/CREATE_NEW_REQUIREMENT'
export const CREATE_NEW_REQUIREMENT_SUCCESS =
  'integration/templates/CREATE_NEW_REQUIREMENT_SUCCESS'
export const CREATE_NEW_REQUIREMENT_FAILURE =
  'integration/templates/CREATE_NEW_REQUIREMENT_FAILURE'

export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS'
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE'

export const DELETE_REQUIREMENT = 'integration/templates/DELETE_REQUIREMENT'
export const DELETE_REQUIREMENT_SUCCESS =
  'integration/templates/DELETE_REQUIREMENT_SUCCESS'
export const DELETE_REQUIREMENT_FAILURE =
  'integration/templates/DELETE_REQUIREMENT_FAILURE'

export const CHANGE_REQUIREMENT = 'integration/templates/CHANGE_REQUIREMENT'
export const CHANGE_REQUIREMENT_SUCCESS =
  'integration/templates/CHANGE_REQUIREMENT_SUCCESS'
export const CHANGE_REQUIREMENT_FAILURE =
  'integration/templates/CHANGE_REQUIREMENT_FAILURE'

export const fetchTemplates = createAction(FETCH_TEMPLATES)
export const deleteTemplate = createAction(DELETE_TEMPLATE)
export const updateTemplateStatus = createAction(UPDATE_TEMPLATE_STATUS)
export const createTemplate = createAction(CREATE_TEMPLATE)
export const fetchCountries = createAction(FETCH_COUNTRIES)
export const fetchTemplate = createAction(FETCH_TEMPLATE)
export const createNewColumn = createAction(CREATE_NEW_COLUMN)
export const deleteTemplateColumn = createAction(DELETE_TEMPLATE_COLUMN)
export const updateTemplateColumn = createAction(UPDATE_TEMPLATE_COLUMN)
export const updateTemplate = createAction(UPDATE_TEMPLATE)
export const createNewRequirement = createAction(CREATE_NEW_REQUIREMENT)
export const deleteRequirement = createAction(DELETE_REQUIREMENT)
export const changeRequirement = createAction(CHANGE_REQUIREMENT)

export default handleActions(
  {
    [FETCH_TEMPLATES]: state => ({ ...state, isLoading: true }),

    [FETCH_TEMPLATES_SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      templates: data,
      isLoading: false
    }),

    [FETCH_TEMPLATES_FAILURE]: state => ({
      ...state,
      isLoading: false
    }),
    [DELETE_TEMPLATE]: (state, { payload: { id } }) => ({ ...state }),
    [UPDATE_TEMPLATE_STATUS]: (state, { payload: { id } }) => ({ ...state }),

    // [DELETE_TEMPLATE_SUCCESS]: (state, { payload: { id } }) => ({
    //   ...state,
    //   templates: state.templates.filter(template => template.id !== id)
    // }),

    // [DELETE_TEMPLATE_FAILURE]: state => ({
    //   ...state
    // }),
    [CREATE_TEMPLATE]: (state, { payload: template }) => ({
      ...state
    }),
    [FETCH_COUNTRIES]: state => ({
      ...state
    }),
    [FETCH_COUNTRIES_SUCCESS]: (state, { payload: { countries } }) => ({
      ...state,
      countries: countries,
      // isLoading: false
      isCountriesFetched: true
    }),

    [FETCH_COUNTRIES_FAILURE]: state => ({
      ...state,
      // isLoading: false
      isCountriesFetched: false
    }),
    [FETCH_TEMPLATE]: state => ({
      ...state
    }),
    [FETCH_TEMPLATE_SUCCESS]: (state, { payload: { template } }) => {
      const newTemplate = template
      const templateIndex = findLastIndex(
        propEq('id', newTemplate.id),
        state.templates
      )
      return {
        ...state,
        isLoading: false,
        templates: ~templateIndex
          ? update(templateIndex, newTemplate, state.templates)
          : [...state.templates, newTemplate]
      }
    },
    [FETCH_TEMPLATE_FAILURE]: state => ({
      ...state,
      isLoading: false
    }),
    [CREATE_NEW_COLUMN]: state => ({
      ...state
    }),
    [DELETE_TEMPLATE_COLUMN]: state => ({
      ...state
    }),
    [UPDATE_TEMPLATE_COLUMN]: (state, { payload: { id } }) => ({
      ...state,
      isColumnLoading: id
    }),
    [UPDATE_TEMPLATE_COLUMN_SUCCESS]: state => ({
      ...state,
      isColumnLoading: null
    }),
    [UPDATE_TEMPLATE_COLUMN_FAILURE]: state => ({
      ...state,
      isColumnLoading: null
    }),
    [UPDATE_TEMPLATE]: state => ({
      ...state,
      isUpdating: true
    }),
    [UPDATE_TEMPLATE_SUCCESS]: (state, { payload: { template } }) => ({
      ...state,
      templates: state.templates.map(item =>
        item.id === template.id ? template : item
      ),
      isUpdating: false
    }),
    [UPDATE_TEMPLATE_FAILURE]: state => ({
      ...state,
      isUpdating: false
    }),
    [CREATE_NEW_REQUIREMENT]: (state, { payload: { column_id } }) => ({
      ...state,
      isColumnLoading: column_id
    }),
    [CREATE_NEW_REQUIREMENT_SUCCESS]: state => ({
      ...state,
      isColumnLoading: null
    }),
    [CREATE_NEW_REQUIREMENT_FAILURE]: state => ({
      ...state,
      isColumnLoading: null
    }),
    [DELETE_REQUIREMENT]: (state, { payload: { column_id } }) => ({
      ...state,
      isColumnLoading: column_id
    }),
    [DELETE_REQUIREMENT_SUCCESS]: state => ({
      ...state,
      isColumnLoading: null
    }),
    [DELETE_REQUIREMENT_FAILURE]: state => ({
      ...state,
      isColumnLoading: null
    }),
    [CHANGE_REQUIREMENT]: (state, { payload: { column_id } }) => ({
      ...state,
      isColumnLoading: column_id
    }),
    [CHANGE_REQUIREMENT_SUCCESS]: state => ({
      ...state,
      isColumnLoading: null
    }),
    [CHANGE_REQUIREMENT_FAILURE]: state => ({
      ...state,
      isColumnLoading: null
    })
  },
  initialState
)
