import axios from 'axios'
import { get, post } from 'utils/request'
import Cookies from 'js-cookie'

// axios.interceptors.request.use(
//   function(config) {
//     const accessToken = Cookies.get('accessToken')
//     const role = localStorage.getItem('role')

//     config.headers['Authorization'] = 'Bearer ' + accessToken

//     // console.log(user, role)
//     if (!config.url.includes('alphaone.ru/Authorize2/')) {
//       // config.headers['user'] = `${user}`

//       config.headers['role'] = role
//     }

//     return config
//   },
//   function(error) {
//     return Promise.reject(error)
//   }
// )

export const signIn = req =>
  post('/Authorize2/Token', req).then(response => response.data)

const accessToken = Cookies.get('accessToken')

export const fetchUserInfo = () =>
  axios.post(
    `https://alphaone.ru/Authorize2/api/Account/UserInfo`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }
  )

export const fetchRoleInfo = () =>
  axios.post(
    `https://alphaone.ru/Authorize2/api/Account/RoleInfo2`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }
  )

export const currentUser = () =>
  get('/current_user').then(response => response.data)

// export const logout = req => post('/account/logoff', req)

// export const logout = () => axios.post(`https://alphaone.ru/account/logoff`, {})
