import { call, put, select, all, takeLatest } from 'redux-saga/effects'

import { getLogsRequest } from './logsApi'
import {
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAILURE,
  FETCH_LOGS,
  SET_CURRENT_PAGE
} from './logsReducer'

// export const getCurrentEmail = state => state.auth.userInfo.email
// export const getCurrentRole = state => state.auth.userInfo.currentRole
// export const getCurrentPage = state => state.logs.currentPage

// export function * fetchLogsSaga () {
//   try {
//     const currentPage = yield select(getCurrentPage)
//       const { data: { data, meta } } = yield call(getLogsRequest, null, currentPage)
//       yield put({ type: FETCH_LOGS_SUCCESS, payload: { data, meta } })
//   } catch (error) {
//     yield put({ type: FETCH_LOGS_FAILURE })
//   }
// }

export const getCurrentEmail = state => state.user.info.Email
export const getCurrentRole = state => state.user.info.role
export const getCurrentPage = state => state.logs.currentPage

export function* fetchLogsSaga() {
  try {
    const email = yield select(getCurrentEmail)
    const role = yield select(getCurrentRole)
    const currentPage = yield select(getCurrentPage)

    const {
      data: { data, meta }
    } = yield call(getLogsRequest, email, currentPage)
    yield put({ type: FETCH_LOGS_SUCCESS, payload: { data, meta } })
  } catch (error) {
    yield put({ type: FETCH_LOGS_FAILURE })
  }
}

export default function*() {
  yield all([
    takeLatest(FETCH_LOGS, fetchLogsSaga),
    takeLatest(SET_CURRENT_PAGE, fetchLogsSaga)
  ])
}
