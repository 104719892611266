// use to style components via styled-components + styled-tools

// import { prop } from "styled-tools"
// style component like color: ${prop('theme.element.main')};
// where theme = file name with themes
// colors = object name that correlates with color category
// elements = name of the color

export default {
  element: {
    main: '#2f80ed',
    true: '#27ae60',
    trueLight: '#e9f7ef',
    false: '#eb5757',
    falseLight: '#fdeeee',
    active: '#2e5bff',
    disabled: '#8b90a0'
  },

  background: {
    gradient:
      'linear-gradient(325.93deg, #05FF9E 2.18%, #0B65A3 78.21%, #CD02FB 106.04%)',
    light: '#ffffff',
    dark: '#f8f8fa'
  }
}
