import { createAction, handleActions } from 'redux-actions'

const initialState = {
  uploaded: false,
  isUploadLoading: false,
  isUploadError: false,

  ftpUploaded: false,
  isFtpUploadLoading: false,
  isFtpUploadError: true,

  ftpStructure: {},
  isFtpStructureLoading: false,
  isFtpStructureError: false
}

export const UPLOAD_FILES = 'integration/reports/UPLOAD_FILES'
export const UPLOAD_FILES_SUCCESS = 'integration/reports/UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILES_FAILURE = 'integration/reports/UPLOAD_FILES_FAILURE'

export const UPLOAD_FTP_FILES = 'integration/reports/UPLOAD_FTP_FILES'
export const UPLOAD_FTP_FILES_SUCCESS =
  'integration/reports/UPLOAD_FTP_FILES_SUCCESS'
export const UPLOAD_FTP_FILES_FAILURE =
  'integration/reports/UPLOAD_FTP_FILES_FAILURE'

export const FETCH_FTP_STRUCTURE = 'integration/reports/FETCH_FTP_STRUCTURE'
export const FETCH_FTP_STRUCTURE_SUCCESS =
  'integration/reports/FETCH_FTP_STRUCTURE_SUCCESS'
export const FETCH_FTP_STRUCTURE_FAILURE =
  'integration/reports/FETCH_FTP_STRUCTURE_FAILURE'

export const RESET_UPLOADED_FILES = 'integration/reports/RESET_UPLOADED_FILES'

export const uploadFiles = createAction(UPLOAD_FILES)
export const uploadFtpFiles = createAction(UPLOAD_FTP_FILES)

export const fetchFtpStructure = createAction(FETCH_FTP_STRUCTURE)

export const resetUploadedFiles = createAction(RESET_UPLOADED_FILES)

export default handleActions(
  {
    [UPLOAD_FILES]: state => {
      return {
        ...state,
        uploaded: false,
        isUploadLoading: true,
        isUploadError: false
      }
    },
    [UPLOAD_FILES_FAILURE]: state => {
      return {
        ...state,
        uploaded: false,
        isUploadLoading: false,
        isUploadError: true
      }
    },
    [UPLOAD_FILES_SUCCESS]: state => {
      return {
        ...state,
        uploaded: true,
        isUploadLoading: false,
        isUploadError: false
      }
    },
    [UPLOAD_FTP_FILES]: state => {
      return {
        ...state,
        ftpUploaded: false,
        isFtpUploadLoading: true,
        isFtpUploadError: false
      }
    },
    [UPLOAD_FTP_FILES_FAILURE]: state => {
      return {
        ...state,
        ftpUploaded: false,
        isFtpUploadLoading: false,
        isFtpUploadError: true
      }
    },
    [UPLOAD_FTP_FILES_SUCCESS]: state => {
      return {
        ...state,
        ftpUploaded: true,
        isFtpUploadLoading: false,
        isFtpUploadError: false
      }
    },
    [FETCH_FTP_STRUCTURE]: state => {
      return {
        ...state,
        ftpStructure: {},
        isFtpStructureLoading: true,
        isFtpStructureError: false
      }
    },
    [FETCH_FTP_STRUCTURE_FAILURE]: state => {
      return {
        ...state,
        ftpStructure: {},
        isFtpStructureLoading: false,
        isFtpStructureError: true
      }
    },
    [FETCH_FTP_STRUCTURE_SUCCESS]: (state, { payload: { ftpStructure } }) => {
      return {
        ...state,
        ftpStructure,
        isFtpStructureLoading: false,
        isFtpStructureError: false
      }
    },
    [RESET_UPLOADED_FILES]: () => initialState
  },
  initialState
)
