import axios from 'axios'
import Cookies from 'js-cookie'

const accessToken = Cookies.get('accessToken')

const isProductionEnv = process.env.NODE_ENV === 'production'
const url = isProductionEnv
  ? 'https://ftpchecker.alphaone.ru'
  : 'http://local.alphaone.ru:3000'

export const getTemplatesRequest = () =>
  axios.get(
    `${url}/api/templates`,
    {},
    {
      headers: {
        Authorization: accessToken
      }
    }
  )

///api/templates

export const deleteTemplateRequest = id => {
  console.log('ID_IN_REQUEST', id)
  console.log('currentUrl', url)
  return axios.delete(
    `${url}/api/templates/${id}`,
    {},
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const createTemplateRequest = template => {
  console.log('TEMPLATE_IN_REQUEST', template)

  return axios.post(
    `${url}/api/templates`,
    { template },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const updateTemplateStatusRequest = (id, template) => {
  console.log('TCL: updateTemplateRequest -> id', id)
  console.log('TCL: updateTemplateRequest -> template', template)

  return axios.patch(
    `${url}/api/templates/${id}`,
    {
      template
    },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const fetchCountriesRequest = () =>
  axios.get('https://alphaone.ru/Authorize2/api/Account/Countries', {
    headers: {
      Authorization: 'Bearer ' + accessToken
    }
  })

export const fetchTemplateRequest = id =>
  axios.get(`${url}/api/templates/${id}`, {
    headers: {
      Authorization: accessToken
    }
  })

export const createNewColumnRequest = (column, id) => {
  console.log('column in request', column)
  console.log('id in request', id)
  return axios.post(
    `${url}/api/columns`,
    {
      column: { ...column, template_id: id }
    },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const deleteTemplateColumnRequest = id => {
  return axios.delete(`${url}/api/columns/${id}`, {
    headers: {
      Authorization: accessToken
    }
  })
}

export const updateTemplateColumnRequest = (id, templateId, params) => {
  return axios.patch(
    `${url}/api/columns/${id}`,
    {
      column: { ...params },
      template_id: templateId
    },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const updateTemplateRequest = (id, template) => {
  return axios.put(
    `${url}/api/templates/${id}`,
    { template },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const createNewRequirementRequest = (requirement, column_id) =>
  axios.post(
    `${url}/api/requirements`,
    {
      requirement: { ...requirement, column_id }
    },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )

export const deleteRequirementRequest = requirement =>
  axios.delete(
    `${url}/api/requirements/${requirement.id}`,
    {},
    {
      headers: {
        Authorization: accessToken
      }
    }
  )

export const changeRequirementRequest = (id, condition, templateId) => {
  console.log('id_in_request', id)
  console.log('condition_in_request', condition)
  console.log('templateId_in_request', templateId)
  return axios.patch(
    `${url}/api/requirements/${id}`,
    {
      template_id: templateId,
      requirement: { ...condition }
    },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}
