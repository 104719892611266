import axios from 'axios'
import Cookies from 'js-cookie'
import download from 'downloadjs'

const accessToken = Cookies.get('accessToken')
// console.log('ТОКЕН!!!', accessToken)
const isProductionEnv = process.env.NODE_ENV === 'production'
const CrossStorageClientUrl = isProductionEnv
  ? 'https://ftpchecker.alphaone.ru/api/reports'
  : 'http://local.alphaone.ru:3000/api/reports'

export const getReportsRequest = () =>
  axios.get(
    CrossStorageClientUrl,
    {},
    {
      headers: {
        Authorization: accessToken
      }
    }
  )

export const deleteReportTemplateRequest = id => {
  return axios.delete(
    `${CrossStorageClientUrl}/${id}`,
    {},
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const updateReportTemplateRequest = (id, report) => {
  return axios.patch(
    `${CrossStorageClientUrl}/${id}`,
    {
      report
    },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const createReportTemplateRequest = report => {
  return axios.post(
    CrossStorageClientUrl,
    { ...report },
    {
      headers: {
        Authorization: accessToken
      }
    }
  )
}

export const fetchReportRequest = id =>
  axios.get(`${CrossStorageClientUrl}/${id}`, {
    headers: {
      Authorization: accessToken
    }
  })

export const downloadReportRequest = (file_names, user, id, reportName) => {
  axios
    .post(
      isProductionEnv
        ? 'https://ftpchecker.alphaone.ru/api/file_report'
        : 'http://local.alphaone.ru:3000/api/file_report',
      {
        file_names,
        user,
        id
      },
      {
        responseType: 'blob'
      }
    )
    .then(res => {
      const isDownloadAll = file_names.length > 1

      const fileName = 'TEMPLATE_' + reportName + ', FILE_NAME_' + file_names[0]
      const fileNames = reportName + '_group_report.xlsx'

      const downloadFileName = `${isDownloadAll ? fileNames : fileName}.xlsx`

      download(res.data, downloadFileName)
    })
}

export const downloadErrorReportRequest = (file_name, user) =>
  axios
    .post(
      isProductionEnv
        ? 'https://ftpchecker.alphaone.ru/api/validation_report'
        : 'http://local.alphaone.ru:3000/api/validation_report',
      {
        file_name,
        user
      },
      {
        responseType: 'blob'
      }
    )
    .then(response => {
      download(response.data, `${file_name}.xlsx`)
    })

export const sendFilesToFTPRequest = (files, user) =>
  axios.post('https://ftpchecker.alphaone.ru/api/ftp_sync', {
    files,
    user
  })
