import { call, put, all, takeLatest } from 'redux-saga/effects'

import {
  getTemplatesRequest,
  deleteTemplateRequest,
  createTemplateRequest,
  updateTemplateStatusRequest,
  fetchCountriesRequest,
  fetchTemplateRequest,
  createNewColumnRequest,
  deleteTemplateColumnRequest,
  updateTemplateColumnRequest,
  updateTemplateRequest,
  createNewRequirementRequest,
  deleteRequirementRequest,
  changeRequirementRequest
} from './templatesApi'
import {
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES,
  DELETE_TEMPLATE,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE_STATUS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  CREATE_NEW_COLUMN,
  DELETE_TEMPLATE_COLUMN,
  UPDATE_TEMPLATE_COLUMN,
  UPDATE_TEMPLATE_COLUMN_SUCCESS,
  UPDATE_TEMPLATE_COLUMN_FAILURE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  CREATE_NEW_REQUIREMENT,
  CREATE_NEW_REQUIREMENT_SUCCESS,
  CREATE_NEW_REQUIREMENT_FAILURE,
  DELETE_REQUIREMENT,
  DELETE_REQUIREMENT_SUCCESS,
  DELETE_REQUIREMENT_FAILURE,
  CHANGE_REQUIREMENT,
  CHANGE_REQUIREMENT_SUCCESS,
  CHANGE_REQUIREMENT_FAILURE
} from './templatesReducer'

export function* fetchTemplatesSaga() {
  try {
    const {
      data: { data }
    } = yield call(getTemplatesRequest)
    yield put({ type: FETCH_TEMPLATES_SUCCESS, payload: { data } })
  } catch (error) {
    yield put({ type: FETCH_TEMPLATES_FAILURE })
  }
}

export function* deleteTemplateSaga({ payload: { id } }) {
  console.log('ID_IN_SAGA', id)
  try {
    yield call(deleteTemplateRequest, id)
    const {
      data: { data }
    } = yield call(getTemplatesRequest)
    yield put({ type: FETCH_TEMPLATES_SUCCESS, payload: { data } })
  } catch (error) {
    yield put({ type: FETCH_TEMPLATES_FAILURE })
  }
}

export function* createTemplateSaga({ payload: { template } }) {
  console.log('TEMPLATE_IN_SAGA', template)
  try {
    yield call(createTemplateRequest, template)
    const {
      data: { data }
    } = yield call(getTemplatesRequest)
    yield put({ type: FETCH_TEMPLATES_SUCCESS, payload: { data } })
  } catch (error) {
    yield put({ type: FETCH_TEMPLATES_FAILURE })
  }
}

export function* updateTemplateStatusSaga({ payload: { id, template } }) {
  console.log('ID_IN_SAGA', id)
  try {
    yield call(updateTemplateStatusRequest, id, template)
    const {
      data: { data }
    } = yield call(getTemplatesRequest)
    yield put({ type: FETCH_TEMPLATES_SUCCESS, payload: { data } })
  } catch (error) {
    yield put({ type: FETCH_TEMPLATES_FAILURE })
  }
}

export function* fetchCountriesSaga() {
  try {
    const { data } = yield call(fetchCountriesRequest)
    const reducedCountries = data.reduce((acc, country) => {
      acc = [
        ...acc,
        {
          label: country.NameFull ? country.NameFull : country.Name,
          value: country.NameFull ? country.NameFull : country.Name
        }
      ]
      return acc
    }, [])
    console.log('RESPONSE_IN_SAGA', reducedCountries)
    yield put({
      type: FETCH_COUNTRIES_SUCCESS,
      payload: { countries: reducedCountries }
    })
  } catch (error) {
    console.log(error)
    yield put({ type: FETCH_COUNTRIES_FAILURE })
  }
}

export function* fetchTemplateSaga({ payload: { id } }) {
  try {
    const {
      data: { data }
    } = yield call(fetchTemplateRequest, id)
    // console.log('DATA_IN_fetchTemplateSaga', data)
    yield put({ type: FETCH_TEMPLATE_SUCCESS, payload: { template: data } })
  } catch (error) {
    console.log(error)
    yield put({ type: FETCH_TEMPLATE_FAILURE })
  }
}

export function* createNewColumnSaga({ payload: { column, id } }) {
  try {
    yield call(createNewColumnRequest, column, id)
    const {
      data: { data }
    } = yield call(fetchTemplateRequest, id)
    yield put({ type: FETCH_TEMPLATE_SUCCESS, payload: { template: data } })
  } catch (error) {
    console.log(error)
    yield put({ type: FETCH_TEMPLATE_FAILURE })
  }
}

export function* deleteTemplateColumnSaga({ payload: { id, templateId } }) {
  try {
    yield call(deleteTemplateColumnRequest, id)
    const {
      data: { data }
    } = yield call(fetchTemplateRequest, templateId)
    yield put({
      type: FETCH_TEMPLATE_SUCCESS,
      payload: { template: data }
    })
  } catch (error) {
    console.log(error)
    yield put({ type: FETCH_TEMPLATE_FAILURE })
  }
}

export function* updateTemplateColumnSaga({
  payload: { id, templateId, params }
}) {
  console.log('id, templateId, params in saga', id, templateId, params)
  try {
    yield call(updateTemplateColumnRequest, id, templateId, params)
    const {
      data: { data }
    } = yield call(fetchTemplateRequest, templateId)
    yield put({
      type: FETCH_TEMPLATE_SUCCESS,
      payload: { template: data }
    })
    yield put({
      type: UPDATE_TEMPLATE_COLUMN_SUCCESS
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: UPDATE_TEMPLATE_COLUMN_FAILURE
    })
  }
}

export function* updateTemplateSaga({ payload: { id, template } }) {
  console.log('id', id)
  console.log('template', template)
  try {
    const {
      data: { data }
    } = yield call(updateTemplateRequest, id, template)
    yield put({
      type: UPDATE_TEMPLATE_SUCCESS,
      payload: { template: data }
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: UPDATE_TEMPLATE_FAILURE
    })
  }
}

export function* createNewRequirementSaga({
  payload: { requirement, column_id, templateId }
}) {
  console.log('requirement', requirement)
  console.log('column_id', column_id)
  console.log('templateId', templateId)
  try {
    yield call(createNewRequirementRequest, requirement, column_id)
    const {
      data: { data }
    } = yield call(fetchTemplateRequest, templateId)
    yield put({
      type: FETCH_TEMPLATE_SUCCESS,
      payload: { template: data }
    })
    yield put({
      type: CREATE_NEW_REQUIREMENT_SUCCESS
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: CREATE_NEW_REQUIREMENT_FAILURE
    })
  }
}

export function* deleteRequirementSaga({
  payload: { requirement, column_id, templateId }
}) {
  console.log('requirement', requirement)
  console.log('column_id', column_id)
  console.log('templateId', templateId)
  try {
    yield call(deleteRequirementRequest, requirement)
    const {
      data: { data }
    } = yield call(fetchTemplateRequest, templateId)
    yield put({
      type: FETCH_TEMPLATE_SUCCESS,
      payload: { template: data }
    })
    yield put({
      type: DELETE_REQUIREMENT_SUCCESS
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: DELETE_REQUIREMENT_FAILURE
    })
  }
}

export function* changeRequirementSaga({
  payload: { condition, id, templateId }
}) {
  console.log('id_in_saga', id)
  console.log('condition_in_saga', condition)
  console.log('templateId_in_saga', templateId)
  try {
    yield call(changeRequirementRequest, id, condition, templateId)
    const {
      data: { data }
    } = yield call(fetchTemplateRequest, templateId)
    yield put({
      type: FETCH_TEMPLATE_SUCCESS,
      payload: { template: data }
    })
    yield put({
      type: CHANGE_REQUIREMENT_SUCCESS
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: CHANGE_REQUIREMENT_FAILURE
    })
  }
}

export default function*() {
  yield all([
    takeLatest(FETCH_TEMPLATES, fetchTemplatesSaga),
    takeLatest(DELETE_TEMPLATE, deleteTemplateSaga),
    takeLatest(CREATE_TEMPLATE, createTemplateSaga),
    takeLatest(UPDATE_TEMPLATE_STATUS, updateTemplateStatusSaga),
    takeLatest(FETCH_COUNTRIES, fetchCountriesSaga),
    takeLatest(FETCH_TEMPLATE, fetchTemplateSaga),
    takeLatest(CREATE_NEW_COLUMN, createNewColumnSaga),
    takeLatest(DELETE_TEMPLATE_COLUMN, deleteTemplateColumnSaga),
    takeLatest(UPDATE_TEMPLATE_COLUMN, updateTemplateColumnSaga),
    takeLatest(UPDATE_TEMPLATE, updateTemplateSaga),
    takeLatest(CREATE_NEW_REQUIREMENT, createNewRequirementSaga),
    takeLatest(DELETE_REQUIREMENT, deleteRequirementSaga),
    takeLatest(CHANGE_REQUIREMENT, changeRequirementSaga)
  ])
}
