import { fork } from 'redux-saga/effects'

import { userSaga } from './user'
import { pbiTokenSaga } from './pbiToken'
import { logsSaga } from './integration/logs'
import { fetchTemplatesSaga } from './integration/templates'
import { reportsSaga } from './integration/reports'
import { uploadSaga } from './integration/upload'

export default function* rootSaga() {
  yield fork(userSaga)
  yield fork(pbiTokenSaga)
  yield fork(logsSaga)
  yield fork(fetchTemplatesSaga)
  yield fork(reportsSaga)
  yield fork(uploadSaga)
}
