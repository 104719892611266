import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

// ---
// CONSTANTS
// ---

export const CHANAGE_ROLE = 'user/CHANAGE_ROLE'

export const CHANAGE_AUTH = 'user/CHANAGE_AUTH'

export const SIGN_IN = 'user/SIGN_IN'
export const SIGN_IN_SUCCESS = 'user/SIGN_IN_SUCCESS'
export const SIGN_IN_FAIL = 'user/SIGN_IN_FAIL'

export const FETCH_USER_INFO = 'user/FETCH_USER_INFO'
export const FETCH_USER_INFO_SUCCESS = 'user/FETCH_USER_INFO_SUCCESS'
export const FETCH_USER_INFO_ERROR = 'user/FETCH_USER_INFO_ERROR'

export const LOAD_SESSION = 'user/LOAD_SESSION'
export const LOAD_SESSION_SUCCESS = 'user/LOAD_SESSION_SUCCESS'
export const LOAD_SESSION_FAIL = 'user/LOAD_SESSION_FAIL'

export const LOGOUT = 'user/LOGOUT'

// ---
// ACTION CREATORS
// ---
export const changeRole = createAction(CHANAGE_ROLE)

export const changeAuth = createAction(CHANAGE_AUTH)

export const signIn = createAction(SIGN_IN)
export const signInSuccess = createAction(SIGN_IN_SUCCESS)
export const signInFail = createAction(SIGN_IN_FAIL)

export const fetchUserInfo = createAction(FETCH_USER_INFO)

export const loadSession = createAction(LOAD_SESSION)
export const loadSessionSuccess = createAction(LOAD_SESSION_SUCCESS)
export const loadSessionFail = createAction(LOAD_SESSION_FAIL)

export const logout = createAction(LOGOUT)

// ---
// INITIAL STATE
// ---
const initialState = Immutable({
  role: 'admin',
  roleList: [],
  profile: '',
  error: '',
  info: null,
  isInfoLoading: false,
  isInfoError: false,
  isAuth: false
})

// ---
// REDUCER
// ---
export default handleActions(
  {
    [CHANAGE_ROLE]: (state, action) =>
      Immutable.merge(state, {
        role: action.payload.role
      }),

    [CHANAGE_AUTH]: (state, action) =>
      Immutable.merge(state, {
        isAuth: action.payload
      }),

    [SIGN_IN]: (state, action) => Immutable.merge(state, { error: '' }),

    [SIGN_IN_SUCCESS]: (state, action) =>
      Immutable.merge(state, {
        error: '',
        profile: action.payload
      }),

    [SIGN_IN_FAIL]: (state, action) =>
      Immutable.merge(state, { error: action.payload }),

    [FETCH_USER_INFO]: state =>
      Immutable.merge(state, {
        info: null,
        isInfoLoading: true,
        isInfoError: false
      }),

    [FETCH_USER_INFO_SUCCESS]: (
      state,
      { payload: { info, roleList, currentRole } }
    ) =>
      Immutable.merge(state, {
        info,
        roleList,
        isInfoLoading: false,
        isInfoError: false
      }),

    [FETCH_USER_INFO_ERROR]: state =>
      Immutable.merge(state, {
        info: null,
        isInfoLoading: false,
        isInfoError: true
      }),

    [LOGOUT]: (state, action) =>
      Immutable.merge(state, {
        error: '',
        profile: '',
        isAuth: false
      })
  },
  initialState
)
