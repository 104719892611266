import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
// import { PersistGate } from 'redux-persist/integration/react'

import App from './App'

import 'react-app-polyfill/ie9'
import './index.css'
import 'assets/fonts/fonts.css'

import configureStore from 'store'

// const store = configureStore().store
const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <PersistGate persistor={configureStore().persistor}> */}
      <App />
      {/* </PersistGate> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
