import axios from 'axios'
import Cookies from 'js-cookie'

const accessToken = Cookies.get('accessToken')

const isProductionEnv = process.env.NODE_ENV === 'production'
const CrossStorageClientUrl = isProductionEnv
  ? 'https://ftpchecker.alphaone.ru/api/logs'
  : 'http://local.alphaone.ru:3000/api/logs'

export const getLogsRequest = (user, page_number) =>
  axios.get(CrossStorageClientUrl, {
    headers: {
      Authorization: accessToken
    },
    params: {
      user,
      page_number
    }
  })
