import { call, put, takeLatest, all, select } from 'redux-saga/effects'
import {
  uploadFiles,
  fetchFtpInfo,
  fetchFtpStructure,
  uploadFtpFiles
} from './api'

import {
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
  FETCH_FTP_STRUCTURE,
  FETCH_FTP_STRUCTURE_SUCCESS,
  FETCH_FTP_STRUCTURE_FAILURE,
  UPLOAD_FTP_FILES,
  UPLOAD_FTP_FILES_SUCCESS,
  UPLOAD_FTP_FILES_FAILURE
} from './reducer'

export const getFoldersWithCountry = state => state.upload.ftpStructure.folders

export function* uploadFilesSaga({ payload: { files } }) {
  try {
    yield call(uploadFiles, files)
    yield put({ type: UPLOAD_FILES_SUCCESS })
  } catch (error) {
    yield put({ type: UPLOAD_FILES_FAILURE })
  }
}

export function* uploadFtpFilesSaga({ payload: { files, user } }) {
  const foldersWithCountry = yield select(getFoldersWithCountry)

  const folders = Object.keys(foldersWithCountry)
  const filesWithCountry = files.reduce((acc, file) => {
    const path = folders.find(folder => file.includes(folder))
    acc = { ...acc, [file]: foldersWithCountry[path] }
    return acc
  }, {})

  try {
    yield call(uploadFtpFiles, filesWithCountry, user)
    yield put({ type: UPLOAD_FTP_FILES_SUCCESS })
  } catch (error) {
    console.log('error', error)
    yield put({ type: UPLOAD_FTP_FILES_FAILURE })
  }
}

// export  function* uploadFtpFilesSaga({ payload: { files, user } }) {
//   try {
//     yield call(uploadFtpFiles, files, user)
//     yield put({ type: UPLOAD_FTP_FILES_SUCCESS })
//   } catch  (error) {
//     yield  put({ type: UPLOAD_FTP_FILES_FAILURE })
//   }
// }

export function* fetchFtpStructureSaga() {
  try {
    const { data } = yield call(fetchFtpInfo)
    const countries = data.map(el => el['Country'])
    const uniqCountries = countries.filter(
      (value, index) => countries.indexOf(value) === index
    )
    const foldersWithCountry = data.reduce((acc, path) => {
      const name = path.FolderFTP.replace(
        /\\\\ftt-vm1\\FTP\\synergos_users\\/i,
        '\\'
      )
        .replace(/\\/g, '/')
        .replace(/\/$/g, '')
        .trim()
      if (name !== '') {
        acc[name] = path.Country
      }
      return acc
    }, {})

    const folders = Object.keys(foldersWithCountry)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort()

    const {
      data: { data: ftpStructure }
    } = yield call(fetchFtpStructure, folders)
    console.log('ftpStructure', ftpStructure)

    yield put({
      type: FETCH_FTP_STRUCTURE_SUCCESS,
      payload: {
        ftpStructure: {
          structure: ftpStructure,
          countries: uniqCountries,
          folders: foldersWithCountry
        }
      }
    })
  } catch (error) {
    console.log('error', error)
    yield put({ type: FETCH_FTP_STRUCTURE_FAILURE })
  }
}

export default function*() {
  yield all([
    takeLatest(UPLOAD_FILES, uploadFilesSaga),
    takeLatest(FETCH_FTP_STRUCTURE, fetchFtpStructureSaga),
    takeLatest(UPLOAD_FTP_FILES, uploadFtpFilesSaga)
  ])
}
