import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

// ---
// CONSTANTS
// ---

export const SET_SECTION = 'market/day_product/SET_SECTION'
export const SET_TAB = 'market/day_product/SET_TAB'
export const SET_YEAR = 'market/day_product/SET_YEAR'
export const SET_TABLE_PAGE = 'market/day_product/SET_TABLE_PAGE'
export const CHANGE_TAG_VALUE = 'market/day_product/CHANGE_TAG_VALUE'
export const RESET_FILTERS = 'market/day_product/RESET_FILTERS'

// ---
// ACTION CREATORS
// ---

export const setSection = createAction(SET_SECTION)
export const setTab = createAction(SET_TAB)
export const setTablePage = createAction(SET_TABLE_PAGE)
export const changeTagValue = createAction(CHANGE_TAG_VALUE)
export const resetFilters = createAction(RESET_FILTERS)

// ---
// INITIAL STATE
// ---
const initialState = Immutable({
  currentSection: 'Товар дня',
  currentTab: {
    block: 'Все'
  },
  tablePage: 1,
  filters: {
    names: [],
    pharmacies: []
  }
})

// ---
// REDUCER
// ---

export default handleActions(
  {
    [SET_SECTION]: (state, action) => {
      return Immutable.merge(state, {
        currentSection: action.payload.section
      })
    },
    [SET_TAB]: (state, action) => {
      const { payload } = action
      return Immutable.merge(state, {
        currentTab: { ...state.currentTab, ...payload }
      })
    },
    [SET_TABLE_PAGE]: (state, action) => {
      return Immutable.merge(state, {
        tablePage: action.payload.page
      })
    },
    [CHANGE_TAG_VALUE]: (state, action) => {
      return Immutable.merge(state, {
        filters: {
          ...state.filters,
          [action.payload.id]: action.payload.value
        }
      })
    },
    [RESET_FILTERS]: state => {
      let emptyFilters = {}
      for (let filter in state.filters) {
        emptyFilters[filter] = []
      }
      return Immutable.merge(state, {
        filters: { ...emptyFilters },
        currentTab: { ...state.currentTab, block: 'Все' }
      })
    }
  },
  initialState
)
