import { createAction, handleActions } from 'redux-actions'

const initialState = {
  logs: [],
  currentPage: 1,
  totalPageNumber: null,
  isLogsFetched: false
}

export const FETCH_LOGS = 'integration/logs/FETCH_LOGS'
export const FETCH_LOGS_SUCCESS = 'integration/logs/FETCH_LOGS_SUCCESS'
export const FETCH_LOGS_FAILURE = 'integration/logs/FETCH_LOGS_FAILURE'
export const SET_CURRENT_PAGE = 'integration/logs/SET_CURRENT_PAGE'

// ACTION CREATORS
export const fetchLogs = createAction(FETCH_LOGS)
export const setCurrentPage = createAction(SET_CURRENT_PAGE)

export default handleActions(
  {
    // FTP INFO
    [FETCH_LOGS]: state => ({ ...state, isLogsFetched: false }),

    [FETCH_LOGS_SUCCESS]: (state, { payload: { data, meta } }) => ({
      ...state,
      logs: data,
      totalPageNumber: meta.total_pages,
      currentPage: meta.page_number,
      isLogsFetched: true
    }),

    [FETCH_LOGS_FAILURE]: state => ({
      ...state,
      isLogsFetched: true,
      logs: []
    }),

    [SET_CURRENT_PAGE]: (state, { payload: { page: currentPage } }) => ({
      ...state,
      currentPage
    })
  },
  initialState
)
