import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

// ---
// CONSTANTS
// ---

export const SET_SECTION = 'market/faltlessAvailability/SET_SECTION'
export const SET_TAB = 'market/faltlessAvailability/SET_TAB'
export const SET_YEAR = 'market/faltlessAvailability/SET_YEAR'
export const SET_TABLE_PAGE = 'market/faltlessAvailability/SET_TABLE_PAGE'
export const CHANGE_TAG_VALUE = 'market/faltlessAvailability/CHANGE_TAG_VALUE'
export const RESET_FILTERS = 'market/faltlessAvailability/RESET_FILTERS'

// ---
// ACTION CREATORS
// ---

export const setSection = createAction(SET_SECTION)
export const setTab = createAction(SET_TAB)
export const setYear = createAction(SET_YEAR)
export const setTablePage = createAction(SET_TABLE_PAGE)
export const changeTagValue = createAction(CHANGE_TAG_VALUE)
export const resetFilters = createAction(RESET_FILTERS)

// ---
// INITIAL STATE
// ---
const initialState = Immutable({
  currentSection: 'Бездефектурное наличие',
  currentTab: {
    block: '1 кв.',
    year: '2019'
  },
  tablePage: 1,
  filters: {
    names: [],
    manufacturers: [],
    pharmacies: [],
    networks: []
  }
})

// ---
// REDUCER
// ---

export default handleActions(
  {
    [SET_SECTION]: (state, action) => {
      return Immutable.merge(state, {
        currentSection: action.payload.section
      })
    },
    [SET_TAB]: (state, action) => {
      const { payload } = action
      return Immutable.merge(state, {
        currentTab: { ...state.currentTab, ...payload }
      })
    },
    [SET_YEAR]: (state, action) => {
      const { payload } = action
      return Immutable.merge(state, {
        currentTab: { ...state.currentTab, ...payload }
      })
    },
    [SET_TABLE_PAGE]: (state, action) => {
      return Immutable.merge(state, {
        tablePage: action.payload.page
      })
    },
    [CHANGE_TAG_VALUE]: (state, action) => {
      return Immutable.merge(state, {
        filters: {
          ...state.filters,
          [action.payload.id]: action.payload.value
        }
      })
    },
    [RESET_FILTERS]: state => {
      let emptyFilters = {}
      for (let filter in state.filters) {
        emptyFilters[filter] = []
      }
      return Immutable.merge(state, {
        filters: { ...emptyFilters },
        currentTab: { ...state.currentTab, block: '1 кв.', year: '2019' }
      })
    }
  },
  initialState
)
