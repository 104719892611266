import axios from 'axios'
import Cookies from 'js-cookie'

axios.interceptors.request.use(
  function(config) {
    const accessToken = Cookies.get('accessToken')
    if (accessToken !== '') {
      config.headers['Authorization'] = 'Bearer ' + accessToken
      return config
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

const isProductionEnv = process.env.NODE_ENV === 'production'

const alphaOneUrl = 'https://alphaone.ru/Authorize2/api'
const url = isProductionEnv
  ? 'https://ftpchecker.alphaone.ru'
  : 'http://local.alphaone.ru:3000' // TODO 'http://local.alphaone.ru:3000'

export const uploadFiles = data => axios.post(`${url}/api/upload`, data)
// export const uploadFiles = data => axios.post(`http://local.alphaone.ru:3000/api/upload`, data)
export const uploadFtpFiles = (files, user) =>
  axios.post(`${url}/api/upload_ftp`, { files, user })

export const fetchFtpInfo = () => axios.get(`${alphaOneUrl}/Account/FTPInfo`)

export const fetchFtpStructure = folders =>
  axios.post(`${url}/api/folders`, { folders })
