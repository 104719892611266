import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

export const FETCH_PBI_TOKEN = 'FETCH_PBI_TOKEN'
export const FETCH_PBI_TOKEN_SUCCESS = 'FETCH_PBI_TOKEN_SUCCESS'
export const FETCH_PBI_TOKEN_ERROR = 'FETCH_PBI_TOKEN_ERROR'

export const fetchPbiToken = createAction(FETCH_PBI_TOKEN)

const initialState = Immutable({
  pbiToken: null,
  isPbiTokenLoading: false,
  isPbiTokenError: false
})

export default handleActions(
  {
    [FETCH_PBI_TOKEN]: state => {
      return Immutable.merge(state, {
        pbiToken: null,
        isPbiTokenLoading: true,
        isPbiTokenError: false
      })
    },

    [FETCH_PBI_TOKEN_SUCCESS]: (state, { payload: { pbiToken } }) => {
      return Immutable.merge(state, {
        pbiToken,
        isPbiTokenLoading: false,
        isPbiTokenError: false
      })
    },

    [FETCH_PBI_TOKEN_ERROR]: state => {
      return Immutable.merge(state, {
        pbiToken: null,
        isPbiTokenLoading: false,
        isPbiTokenError: true
      })
    }
  },
  initialState
)
