import { CrossStorageClient } from 'cross-storage'
import { takeLatest, all, call, put } from 'redux-saga/effects'
import {
  SIGN_IN,
  LOGOUT,
  FETCH_USER_INFO,
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_SUCCESS
} from './index'
import { signInSuccess, signInFail } from './index'
import { signIn, fetchUserInfo, fetchRoleInfo, logout } from './userApi'
import { setupToken } from 'utils/request'
import Cookie from 'js-cookie'

const roleTransformer = role => {
  switch (role) {
    case 'Управление связками':
      return 'pharmacy'
    case 'Сотрудник АСНА':
      return 'asna'
    case 'Управление связками (НСИ)':
      return 'bindsManage'
    case 'Руководитель НСИ':
      return 'admin'
    case 'Менеджер Беларусь':
      return 'managerBelarus'
    default:
      return 'asna'
  }
}

const signInSaga = function*(action) {
  const { username, password } = action.payload

  let req = `username=${username}&password=${password}&grant_type=password`
  try {
    const data = yield call(signIn, req)
    yield call(saveToken, data.access_token)
    yield put(signInSuccess(data))
  } catch (error) {
    yield put(signInFail(error.response.data.error_description))
  }
}

const fetchUserInfoSaga = function*() {
  try {
    // console.log('TOKEN_IN_SAGA!!!', token)
    const { data } = yield call(fetchUserInfo)
    const { data: roleInfo } = yield call(fetchRoleInfo)
    // console.log('roleInfo', roleInfo)
    const allRoles = roleInfo.map(item => item.Name)
    // console.log('allRoles', allRoles)
    const roleList = allRoles.map(role => roleTransformer(role))
    // console.log('roleList', roleList)
    const role = roleList[0]

    localStorage.setItem('role', role)

    yield put({
      type: FETCH_USER_INFO_SUCCESS,
      payload: { info: data, roleList, currentRole: role }
    })
  } catch (error) {
    yield put({ type: FETCH_USER_INFO_ERROR })
  }
}

const logoutSaga = function*() {
  try {
    Cookie.remove('accessToken', { path: '/', domain: '.alphaone.ru' })
    Cookie.remove('username', { path: '/', domain: '.alphaone.ru' })
    // logout().then(() => (window.location = 'http://local.alphaone.ru:3001/'))
    // .then(() => (window.location = 'https://asnalanding.brightlab.solutions/'

    // const storage = new CrossStorageClient('https://asnalanding.brightlab.solutions/')
    // storage
    //   .onConnect()
    //   .then(function() {
    //     return storage.del('token')
    //   })
    //   .then(function() {
    //     return storage.del('profile')
    //   })
    //   .then(() => (window.location = 'https://asnalanding.brightlab.solutions/'))
  } catch (error) {
    yield put(signInFail(error.response.data.message))
  }
}

export function saveToken(token) {
  setupToken(token)
  localStorage.setItem('token', token)
}

export default function*() {
  yield all([
    takeLatest(SIGN_IN, signInSaga),
    takeLatest(FETCH_USER_INFO, fetchUserInfoSaga),
    // takeLatest(LOAD_SESSION, loadSessionSaga),
    takeLatest(LOGOUT, logoutSaga)
  ])
}
