import React, { PureComponent, lazy, Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
// import { CrossStorageClient } from 'cross-storage'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'

import { saveToken } from 'store/user/userSaga'
import { signInSuccess, changeAuth, fetchUserInfo } from 'store/user'
import { fetchTemplates } from 'store/integration/templates'

import theme from 'utils/theme'
const Router = lazy(() => import('./router'))
const PageWrapper = lazy(() => import('modules/navigation/pageWrapper'))

const isProductionEnv = process.env.NODE_ENV === 'production'
const CrossStorageClientUrl = isProductionEnv
  ? 'https://asnalanding.brightlab.solutions/'
  : 'http://local.alphaone.ru:3001'

class App extends PureComponent {
  componentDidMount = () => {
    const {
      signInSuccess,
      fetchUserInfo,
      changeAuth,
      fetchTemplates
    } = this.props

    // if (Cookies.get('accessToken')) {
    changeAuth({ isAuth: true })
    // const token = Cookies.get('accessToken')
    // fetchUserInfo({ token: token })
    fetchUserInfo()
    fetchTemplates()
    // }
  }

  render() {
    const { isAuth, isInfoLoading } = this.props

    if (isInfoLoading) {
      return <div>Loading...</div>
    }
    // const accessToken = Cookies.get('accessToken')

    // if (accessToken) {
    return (
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>Loading...</div>}>
          <PageWrapper isAuth={isAuth}>
            <Router />
          </PageWrapper>
        </Suspense>
      </ThemeProvider>
    )
    // } else {
    //   return (window.location = CrossStorageClientUrl)
    // }
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.user.isAuth,
    isInfoLoading: state.user.isInfoLoading
  }
}

const mapDispatchToProps = {
  signInSuccess,
  fetchUserInfo,
  changeAuth,
  fetchTemplates
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
