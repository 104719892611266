import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { enableBatching } from 'redux-batched-actions'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './saga'

import user from './user'
import { pbiTokenReducer as pbiToken } from './pbiToken'
import marketAssortment from './market/marketAssortment'
import marketDayProduct from './market/marketDayProduct'
import eventPerfomance from './market/eventPerfomance'
import faltlessAvailability from './market/faltlessAvailability'
import reconciliation from './market/reconciliation'
import permittedProviders from './market/permittedProviders'
import navigation from './navigation'
import analytics from './analytics'
import employeesList from './admin/employeesList'
import users from './admin/users'
import logs from './integration/logs/logsReducer'
import templates from './integration/templates/templatesReducer'
import reports from './integration/reports/reportsReducer'
import { reducer as upload } from './integration/upload/'

// ---
// REDUCERS
// ---

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]

  const enhancer = composeWithDevTools(applyMiddleware(...middlewares))

  const rootReducer = enableBatching(
    combineReducers({
      user,
      pbiToken,
      marketAssortment,
      marketDayProduct,
      eventPerfomance,
      faltlessAvailability,
      reconciliation,
      permittedProviders,
      navigation,
      analytics,
      employeesList,
      users,
      logs,
      templates,
      reports,
      upload
    })
  )

  const store = createStore(rootReducer, enhancer)

  sagaMiddleware.run(rootSaga)

  return store
}
