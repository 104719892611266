import { handleActions, createAction } from 'redux-actions'
import Immutable from 'seamless-immutable'

// ---
// CONSTANTS
// ---

export const NAVIGATE = 'navigation/NAVIGATE'

// ---
// ACTION CREATORS
// ---
export const navigate = createAction(NAVIGATE)

// ---
// INITIAL STATE
// ---
const initialState = Immutable({
  page: '',
  section: ''
})

// ---
// REDUCER
// ---
export default handleActions(
  {
    [NAVIGATE]: (state, action) =>
      Immutable.merge(state, {
        page: action.payload.page,
        section: action.payload.section ? action.payload.section : null
      })
  },
  initialState
)
