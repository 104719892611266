import { takeLatest, all, call, put } from 'redux-saga/effects'
import { fetchPbiToken } from './pbiTokenApi'
import {
  FETCH_PBI_TOKEN,
  FETCH_PBI_TOKEN_SUCCESS,
  FETCH_PBI_TOKEN_ERROR
} from './pbiTokenReducer'

const fetchPbiTokenSaga = function*({ payload }) {
  try {
    const { data } = yield call(fetchPbiToken, payload)
    yield put({ type: FETCH_PBI_TOKEN_SUCCESS, payload: { pbiToken: data } })
  } catch (error) {
    console.error(error)
    yield put({ type: FETCH_PBI_TOKEN_ERROR })
  }
}

export default function*() {
  yield all([takeLatest(FETCH_PBI_TOKEN, fetchPbiTokenSaga)])
}
