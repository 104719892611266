import { call, put, all, takeLatest } from 'redux-saga/effects'
import {
  getReportsRequest,
  deleteReportTemplateRequest,
  updateReportTemplateRequest,
  fetchReportRequest,
  downloadReportRequest,
  downloadErrorReportRequest,
  sendFilesToFTPRequest,
  createReportTemplateRequest
} from './reportsApi'

import {
  FETCH_REPORT_COLLECTION,
  FETCH_REPORT_COLLECTION_SUCCESS,
  FETCH_REPORT_COLLECTION_FAILURE,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  DELETE_REPORT_TEMPLATE,
  UPDATE_REPORT_TEMPLATE,
  UPDATE_REPORT_TEMPLATE_SUCCESS,
  UPDATE_REPORT_TEMPLATE_FAILURE,
  CREATE_REPORT_TEMPLATE,
  CREATE_REPORT_TEMPLATE_SUCCESS,
  CREATE_REPORT_TEMPLATE_FAILURE,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_FAILURE,
  DOWNLOAD_REPORT,
  DOWNLOAD_ERROR_REPORT,
  DOWNLOAD_ERROR_REPORT_SUCCESS,
  DOWNLOAD_ERROR_REPORT_FAILURE,
  SEND_FILES_TO_FTP,
  SEND_FILES_TO_FTP_SUCCESS,
  SEND_FILES_TO_FTP_FAILURE
} from './reportsReducer'

export function* fetchReportCollectionSaga() {
  try {
    const {
      data: { data: reportCollection }
    } = yield call(getReportsRequest)
    yield put({
      type: FETCH_REPORT_COLLECTION_SUCCESS,
      payload: { reportCollection }
    })
  } catch (error) {
    console.log('error', error)
    yield put({ type: FETCH_REPORT_COLLECTION_FAILURE })
  }
}

export function* deleteReportTemplateSaga({ payload: { id } }) {
  try {
    yield call(deleteReportTemplateRequest, id)
    const {
      data: { data: reportCollection }
    } = yield call(getReportsRequest)
    yield put({
      type: FETCH_REPORT_COLLECTION_SUCCESS,
      payload: { reportCollection }
    })
  } catch (error) {
    yield put({ type: FETCH_REPORT_COLLECTION_FAILURE })
  }
}

export function* updateReportTemplateSaga({
  payload: { id, report, callback }
}) {
  try {
    const { data } = yield call(updateReportTemplateRequest, id, report)
    yield put({ type: UPDATE_REPORT_TEMPLATE_SUCCESS })
    callback()
  } catch (error) {
    yield put({ type: UPDATE_REPORT_TEMPLATE_FAILURE })
  }
}

// export function* createReportTemplateSaga({ payload: { report } }) {
//   try {
//     yield call(createReportTemplateRequest, report)
//     const {
//       data: { data: reportCollection }
//     } = yield call(getReportsRequest)
//     yield put({
//       type: FETCH_REPORT_COLLECTION_SUCCESS,
//       payload: { reportCollection }
//     })
//   } catch (error) {
//     yield put({ type: FETCH_REPORT_COLLECTION_FAILURE })
//   }
// }

export function* fetchReportSaga({ payload: { id } }) {
  try {
    const {
      data: { data }
    } = yield call(fetchReportRequest, id)
    yield put({ type: FETCH_REPORT_SUCCESS, payload: { template: data } })
  } catch (error) {
    console.log(error)
    yield put({ type: FETCH_REPORT_FAILURE })
  }
}

export function* createReportTemplateSaga({ payload: { report, callback } }) {
  try {
    const { data } = yield call(createReportTemplateRequest, report)
    yield put({
      type: CREATE_REPORT_TEMPLATE_SUCCESS,
      payload: { data: report }
    })
    callback()
  } catch (error) {
    console.log(error)
    yield put({
      type: CREATE_REPORT_TEMPLATE_FAILURE
    })
  }
}

export function* downloadRerportSaga({
  payload: { file_names, id, user, reportName }
}) {
  try {
    yield call(downloadReportRequest, file_names, user, id, reportName)
    yield put({ type: DOWNLOAD_REPORT_SUCCESS })
  } catch (error) {
    console.log('error', error)
    yield put({ type: DOWNLOAD_REPORT_FAILURE })
  }
}

export function* downloadErrorReportSaga({ payload: { file_name, user } }) {
  try {
    yield call(downloadErrorReportRequest, file_name, user)
    yield put({ type: DOWNLOAD_ERROR_REPORT_SUCCESS })
  } catch (error) {
    console.log('error', error)
    yield put({ type: DOWNLOAD_ERROR_REPORT_FAILURE })
  }
}

export function* sendFilesToFTPSaga({ payload: { files, user } }) {
  try {
    const folders = files.map(f => f.report.full_path)
    yield call(sendFilesToFTPRequest, folders, user)
    yield put({ type: SEND_FILES_TO_FTP_SUCCESS })
    // yield put({ type: SET_ALERT, payload: {alert: {type: 'success', message: 'Задача отправлена на обработку'}} })
  } catch (error) {
    console.log('error', error)
    yield put({ type: SEND_FILES_TO_FTP_FAILURE })
    // yield put({ type: SET_ALERT, payload: {alert: {type: 'error', message: 'Ошибка при выгрузке'}} })
  }
}

export default function*() {
  yield all([
    takeLatest(FETCH_REPORT_COLLECTION, fetchReportCollectionSaga),
    takeLatest(FETCH_REPORT, fetchReportSaga),
    takeLatest(DELETE_REPORT_TEMPLATE, deleteReportTemplateSaga),
    takeLatest(UPDATE_REPORT_TEMPLATE, updateReportTemplateSaga),
    takeLatest(CREATE_REPORT_TEMPLATE, createReportTemplateSaga),
    takeLatest(DOWNLOAD_REPORT, downloadRerportSaga),
    takeLatest(DOWNLOAD_ERROR_REPORT, downloadErrorReportSaga),
    takeLatest(SEND_FILES_TO_FTP, sendFilesToFTPSaga)
  ])
}
